import { createBrowserRouter } from 'react-router-dom';
import AllNotificationView from '../../components/Notification/AllNotificationView';
import DashboardLayout from '../../layout/DashboardLayout';
import EasyLifeLayout from '../../layout/EasyLifeLayout';
import Main from '../../layout/Main';
import ADC from '../../pages/ADC/ADC/ADC';
import ADCPartnerDetails from '../../pages/ADC/ADCPartnerDetails/ADCPartnerDetails';
import ADCPartnerDetailsBkash from '../../pages/ADC/ADCPartnerDetailsBkash/ADCPartnerDetailsBkash';
import ADCPartnerDetailsBkashRMG from '../../pages/ADC/ADCPartnerDetailsBkashRMG/ADCPartnerDetailsBkashRMG';
import ADCPartnerDetailsCarnival from '../../pages/ADC/ADCPartnerDetailsCarnival/ADCPartnerDetailsCarnival';
import ADCPartnerDetailsJotno from '../../pages/ADC/ADCPartnerDetailsJotno/ADCPartnerDetailsJotno';
import ADCPartnerDetailsNagad from '../../pages/ADC/ADCPartnerDetailsNagad/ADCPartnerDetailsNagad';
import ADCPartnerDetailsOkWallet from '../../pages/ADC/ADCPartnerDetailsOkWallet/ADCPartnerDetailsOkWallet';
import ADCPartnerDetailsRobiShop from '../../pages/ADC/ADCPartnerDetailsRobiShop/ADCPartnerDetailsRobiShop';
import ADCPartnerDetailsTruckLagbe from '../../pages/ADC/ADCPartnerDetailsTruckLagbe/ADCPartnerDetailsTruckLagbe';
import ADCPartnerDetailsUpay from '../../pages/ADC/ADCPartnerDetailsUpay/ADCPartnerDetailsUpay';
import AboutEasylifeViewAll from '../../pages/AboutEsayLife/AboutEasyLifeMedia/AboutEasylifeViewAll';
import MediaSingleContentDetails from '../../pages/AboutEsayLife/AboutEasyLifeMedia/MediaSingleContentDetails';
import AboutEsayLife from '../../pages/AboutEsayLife/AboutEsayLife/AboutEsayLife';
import DetailsServiceProduct from '../../pages/AboutEsayLife/ServicesPrododuct/DetailsServiceProduct';
import EasylifeSingleProductDetails from '../../pages/AboutEsayLife/ServicesPrododuct/EasylifeSingleProductDetails';
import AboutUs from '../../pages/AboutUs/AboutUs/AboutUs';
import AgentList from '../../pages/AgentList/AgentList/AgentList';
import BMICalculator from '../../pages/BMICalculator/BMICalculator';
import Bancassurance from '../../pages/Bancassurance/Bancassurance';
import BoardOfDirectors from '../../pages/BoardOfDirectors/BoardOfDirectors/BoardOfDirectors';
import Career from '../../pages/Career/Career/Career';
import SingleCareerPosition from '../../pages/Career/SingleCareerPosition/SingleCareerPosition';
import OurPlans from '../../pages/CatagoryPlans/OurPlans/OurPlans';
import ChairmanMeassage from '../../pages/ChairmanMeassage/ChairmanMeassage/ChairmanMeassage';
import Claims from '../../pages/Claims/Claims';
import Contactus from '../../pages/ContactUs/Contactus';
import CoreValue from '../../pages/CoreValue/CoreValue/CoreValue';
import CorporateSolutions from '../../pages/CorporateSolutions/CorporateSolutions';
import AllBookingList from '../../pages/Dashboard/AllBookingList/AllBookingList';
import BoardOfDirector from '../../pages/Dashboard/BoardOfDirector/BoardOfDirector';
import Dashboard from '../../pages/Dashboard/Dashboard/Dashboard';
import AgentPdfList from '../../pages/Dashboard/DashboardAllAgentList/AgentPdfList/AgentPdfList';
import DashboardAllAgentList from '../../pages/Dashboard/DashboardAllAgentList/DashboardAllAgentList';
import DashboardClaimsForm from '../../pages/Dashboard/DashboardClaimsForm/DashboardClaimsForm';
import DashboardClaimsFormView from '../../pages/Dashboard/DashboardClaimsForm/DashboardClaimsFormView';
import DashboardClientMessage from '../../pages/Dashboard/DashboardClientMessage/DashboardClientMessage';
import DashboardDigitalLoyaltyOffer from '../../pages/Dashboard/DashboardDigitalLoyaltyOffer/DashboardDigitalLoyaltyOffer';
import DashboardDigitalLoyaltyView from '../../pages/Dashboard/DashboardDigitalLoyaltyOffer/DashboardDigitalLoyaltyView';
import DashboardLoyaltyOffer from '../../pages/Dashboard/DashboardDigitalLoyaltyOffer/DashboardLoyaltyOffer';
import DashboardExtendManagementTeam from '../../pages/Dashboard/DashboardExtendManagementTeam/DashboardExtendManagementTeam';
import DashboardFAQ from '../../pages/Dashboard/DashboardFAQ/DashboardFAQ';
import DashboardFAQView from '../../pages/Dashboard/DashboardFAQ/DashboardFAQView';
import DashboardJobApplied from '../../pages/Dashboard/DashboardJobs/DashboardJobApplied/DashboardJobApplied';
import DashboardJobs from '../../pages/Dashboard/DashboardJobs/DashboardJobs';
import DashboardPostAJob from '../../pages/Dashboard/DashboardJobs/DashboardPostAJob/DashboardPostAJob';
import DashboardViewJobPost from '../../pages/Dashboard/DashboardJobs/DashboardPostAJob/DashboardViewJobPost';
import DashboardLeads from '../../pages/Dashboard/DashboardLeads/DashboardLeads';
import DashboardLeadsView from '../../pages/Dashboard/DashboardLeads/DashboardLeadsView';
import DashboardLocateBranch from '../../pages/Dashboard/DashboardLocateBranch/DashboardLocateBranch';
import DashboardViewAllOfficeBranch from '../../pages/Dashboard/DashboardLocateBranch/DashboardViewAllOfficeBranch';
import DashbaordManagementTeam from '../../pages/Dashboard/DashboardManagementTeam/DashbaordManagementTeam';
import DashBoardImageGallery from '../../pages/Dashboard/DashboardMediaCorner/DashBoardImageGallery/DashBoardImageGallery';
import DashboardImageGallerySinglePhoto from '../../pages/Dashboard/DashboardMediaCorner/DashBoardImageGallery/DashboardImageGallerySinglePhoto';
import DashboardImageGalleryView from '../../pages/Dashboard/DashboardMediaCorner/DashBoardImageGallery/DashboardImageGalleryView';
import DashBoardVideo from '../../pages/Dashboard/DashboardMediaCorner/DashBoardVideo/DashBoardVideo';
import DashboardVideoView from '../../pages/Dashboard/DashboardMediaCorner/DashBoardVideo/DashboardVideoView';
import DashboardAddBlog from '../../pages/Dashboard/DashboardMediaCorner/DashboardAddBlog/DashboardAddBlog';
import DashboardViewBlog from '../../pages/Dashboard/DashboardMediaCorner/DashboardAddBlog/DashboardViewBlog';
import DashboardAddNews from '../../pages/Dashboard/DashboardMediaCorner/DashboardAddNews/DashboardAddNews';
import DashboardViewNews from '../../pages/Dashboard/DashboardMediaCorner/DashboardAddNews/DashboardViewNews';
import DashboardMediaConer from '../../pages/Dashboard/DashboardMediaCorner/DashboardMediaConer';
import DashboardPress from '../../pages/Dashboard/DashboardMediaCorner/DashboardPress/DashboardPress';
import DashboardPressView from '../../pages/Dashboard/DashboardMediaCorner/DashboardPress/DashboardPressView';
import DashBoardReview from '../../pages/Dashboard/DashboardMediaCorner/DashboardReview/DashBoardReview';
import DashboardReviewView from '../../pages/Dashboard/DashboardMediaCorner/DashboardReview/DashboardReviewView';
import DashboardMilestoneView from '../../pages/Dashboard/DashboardMilestones/DashboardMilestoneView';
import DashboardMilestones from '../../pages/Dashboard/DashboardMilestones/DashboardMilestones';
import DashboardPartnerChannels from '../../pages/Dashboard/DashboardPartnerChannels/DashboardPartnerChannels';
import DashboardPreferredHospital from '../../pages/Dashboard/DashboardPreferredHospital/DashboardPreferredHospital';
import DashboardViewAllHospital from '../../pages/Dashboard/DashboardPreferredHospital/DashboardViewAllHospital';
import AddStaffList from '../../pages/Dashboard/DashboardStaffList/AddStaffList/AddStaffList';
import DashboardStaffList from '../../pages/Dashboard/DashboardStaffList/DashboardStaffList';
import EasylifeDashboard from '../../pages/Dashboard/EasylifeDashboard/EasylifeDashboard';
import AddMedia from '../../pages/Dashboard/EasylifeDashboard/Media/AddMedia';
import ViewMedia from '../../pages/Dashboard/EasylifeDashboard/Media/ViewMedia';
import AddProduct from '../../pages/Dashboard/EasylifeDashboard/Products/AddProduct';
import ViewProduct from '../../pages/Dashboard/EasylifeDashboard/Products/ViewProduct';
import PartnerLogoView from '../../pages/Dashboard/PartnerLogo/PartnerLogoView';
import PrartnerLogo from '../../pages/Dashboard/PartnerLogo/PrartnerLogo';
import DashboardAddPlans from '../../pages/Dashboard/Plans/DashboardAddPlans/DashboardAddPlans';
import DashboardViewPlans from '../../pages/Dashboard/Plans/DashboardAddPlans/DashboardViewPlans';
import DashboardCategoryPlans from '../../pages/Dashboard/Plans/DashboardCategoryPlans/DashboardCategoryPlans';
import DashboardViewCategoryPlans from '../../pages/Dashboard/Plans/DashboardCategoryPlans/DashboardViewCategoryPlans';
import DashboardSubCategoryPlans from '../../pages/Dashboard/Plans/DashboardSubCategoryPlans/DashboardSubCategoryPlans';
import DashboardSubCategoryViewPlans from '../../pages/Dashboard/Plans/DashboardSubCategoryPlans/DashboardSubCategoryViewPlans';
import Plans from '../../pages/Dashboard/Plans/Plans';
import SEO from '../../pages/Dashboard/SEO/SEO';
import UserCreate from '../../pages/Dashboard/Users/UserCreate/UserCreate';
import UserRole from '../../pages/Dashboard/Users/UserRole/UserRole';
import Users from '../../pages/Dashboard/Users/Users';
import DigitalChannels from '../../pages/DigitalChannels/DigitalChannels';
import DigitalLoyaltyCard from '../../pages/DigitalLoyaltyCard/DigitalLoyaltyCard';
import EasyLifeCancerCareCenter from '../../pages/EasyLifeCancerCareCenter/EasyLifeCancerCareCenter/EasyLifeCancerCareCenter';
import DigitalOfferViewAllCards from '../../pages/EasyLifeDigitalOffers/DigitalOfferViewAllCards';
import EasyLifeDigitalOffers from '../../pages/EasyLifeDigitalOffers/EasyLifeDigitalOffers/EasyLifeDigitalOffers';
import EasyLifeContact from '../../pages/EasyLifePages/EasyLifeContact/EasyLifeContact';
import EasyLifeFAQ from '../../pages/EasyLifePages/More/EasyLifeFAQ/EasyLifeFAQ';
import EasyLifeMyProfile from '../../pages/EasyLifePages/More/EasyLifeMyProfile/EasyLifeMyProfile';
import EasyLifePrivacyPolicy from '../../pages/EasyLifePages/More/EasyLifePrivacyPolicy/EasyLifePrivacyPolicy';
import GLILPrivacyPolicy from '../../pages/GLILPrivacyPolicy/GLILPrivacyPolicy'
import EasyLifeProduct from '../../pages/EasyLifeProduct/EasyLifeProduct/EasyLifeProduct';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import ExtendedManagementCommittee from '../../pages/ExtendedManagementCommittee/ExtendedManagementCommittee';
import FAQ from '../../pages/FAQ/FAQ';
import Home from '../../pages/Home/Home/Home';
import ImageGallery from '../../pages/ImageGallery/ImageGallery';
import LocateBranch from '../../pages/LocateBranch/LocateBranch';
import Login from '../../pages/Login/Login';
import ManagementTeam from '../../pages/ManagementTeam/ManagementTeam';
import MediaCorner from '../../pages/MediaCorner/MediaCorner/MediaCorner';
import Microinsurance from '../../pages/Microinsurance/Microinsurance';
import Milestone from '../../pages/Milestones/Milestone/Milestone';
import More from '../../pages/More/More';
import Payment from '../../pages/Payment/Payment';
import PlansSubCatagoryPlans from '../../pages/PlansSubCatagoryPlans/PlansSubCatagoryPlans';
import PreferredHospital from '../../pages/PreferredHospital/PreferredHospital/PreferredHospital';
import PremiumCalculator from '../../pages/PremiumCalculator/PremiumCalculator';
import ReatialProduct from '../../pages/ReatailProduct/ReatialProduct';
import ShareholderList from '../../pages/ShareholderList/ShareholderList/ShareholderList';
import SingleContent from '../../pages/SingleContent/SingleContent';
import SingleContentBlog from '../../pages/SingleContent/SingleContentBlog';
import SingleContentPress from '../../pages/SingleContent/SingleContentPress';
import StaffList from '../../pages/StaffList/StaffList';
import SubCatagoryPlans from '../../pages/SubCatagoryPlans/SubCatagoryPlans';
import Support from '../../pages/Support/Support';
import TaxRebate from '../../pages/TaxRebate/TaxRebate';
import Translate from '../../pages/Translate/Translate';
import AdminRoutes from '../AdminRoutes/AdminRoutes';
import PrivateRoutes from '../PrivateRoutes/PrivateRoutes';
import DashboardPopUp from './../../pages/Dashboard/DashBoardPopUp/DashboardPopUp';
import DashboardLoyaltyOfferCategory from './../../pages/Dashboard/DashboardDigitalLoyaltyOffer/DashboardLoyaltyOfferCategory/DashboardLoyaltyOfferCategory';
import DashboardLoyaltyOfferCategoryView from './../../pages/Dashboard/DashboardDigitalLoyaltyOffer/DashboardLoyaltyOfferCategory/DashboardLoyaltyOfferCategoryView';
import DashboardFormLibrary from './../../pages/Dashboard/DashboardFormLibrary/DashboardFormLibrary';
import DashboardFormLibraryView from './../../pages/Dashboard/DashboardFormLibrary/DashboardFormLibraryView';
import DashboardPartnerChannelsView from './../../pages/Dashboard/DashboardPartnerChannels/DashboardPartnerChannelsView';
import EasyLifeGuardianAccidentalCare from '../../pages/EasyLifeGuardianAccidentalCare/EasyLifeGuardianAccidentalCare';
import EasyLifeGuardianTravelCare from '../../pages/EasyLifeGuardianTravelCare/EasyLifeGuardianTravelCare';
import SpecialInsurancePlan from '../../pages/AboutEsayLife/ServicesPrododuct/SpecialInsurancePlan/SpecialInsurancePlan';
import DashboardNoticeBoard from '../../pages/Dashboard/DashboardNoticeBoard/DashboardNoticeBoard';
import DashboardNoticeBoardView from '../../pages/Dashboard/DashboardNoticeBoard/DashboardNoticeBoardView';
import NoticeBoard from '../../pages/NoticeBoard/NoticeBoard';
import DashboardBancassurance from '../../pages/Dashboard/DashboardBancassurance/DashboardBancassurance';
import DashboardBancassuranceIntroduction from '../../pages/Dashboard/DashboardBancassurance/DashboardBancassuranceIntroduction/DashboardBancassuranceIntroduction';
import DashboardBancassuranceWhyGuardinLife from '../../pages/Dashboard/DashboardBancassurance/DashboardBancassuranceWhyGuardinLife/DashboardBancassuranceWhyGuardinLife';
import DashboardBancassuranceService from '../../pages/Dashboard/DashboardBancassurance/DashboardBancassuranceService/DashboardBancassuranceService';
import DashboardBancassuranceBenfits from '../../pages/Dashboard/DashboardBancassurance/DashboardBancassuranceBenfits/DashboardBancassuranceBenfits';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Main />,
        errorElement: <ErrorPage />,
        children: [
            { path: '/', element: <Home /> },
            { path: '/home', element: <Home /> },
            { path: '/about-us', element: <AboutUs /> },
            { path: '/plans', element: <OurPlans /> },
            { path: '/locate-branch', element: <LocateBranch /> },
            { path: '/retail-product', element: <ReatialProduct /> },
            { path: '/corporate-solutions', element: <CorporateSolutions /> },
            { path: '/microinsurance', element: <Microinsurance /> },
            { path: '/bancassurance', element: <Bancassurance /> },
            { path: '/digital-channels', element: <DigitalChannels /> },
            { path: '/chairman-message', element: <ChairmanMeassage /> },
            { path: '/board-directors', element: <BoardOfDirectors /> },
            { path: '/management-team', element: <ManagementTeam /> },
            { path: '/payment', element: <Payment /> },
            { path: '/privacy_policy', element: <GLILPrivacyPolicy /> },
            {
                path: '/extended-management-committee',
                element: <ExtendedManagementCommittee />,
            },
            { path: '/media-corner', element: <MediaCorner /> },
            { path: '/staff-list', element: <StaffList /> },
            { path: '/agent-list', element: <AgentList /> },
            { path: '/claims', element: <Claims /> },
            { path: '/more', element: <More /> },
            {
                path: '/single-news/:id',
                element: <SingleContent />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/news/${params.id}`
                    ),
            },
            {
                path: '/single-blog/:id',
                element: <SingleContentBlog />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/blog/${params.id}`
                    ),
            },
            {
                path: '/single-press/:id',
                element: <SingleContentPress />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/press/${params.id}`
                    ),
            },
            {
                path: '/single-image/:id',
                element: <ImageGallery />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/media-photo/${params.id}`
                    ),
            },
            { path: '/preferred-hospital', element: <PreferredHospital /> },
            { path: '/career', element: <Career /> },
            { path: '/faq', element: <FAQ /> },
            { path: '/form', element: <Support /> },
            { path: '/notice-board', element: <NoticeBoard /> },
            { path: '/digital-loyalty-offer', element: <DigitalLoyaltyCard /> },
            { path: '/core-values', element: <CoreValue /> },
            { path: '/shareholder-list', element: <ShareholderList /> },
            { path: '/milestones', element: <Milestone /> },
            { path: '/translate', element: <Translate /> },
            { path: '/premium-calculator', element: <PremiumCalculator /> },
            { path: '/premium-calculator', element: <PremiumCalculator /> },
            { path: '/tax-rebate', element: <TaxRebate /> },

            {
                path: '/single-career-position/:id',
                element: <SingleCareerPosition />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/job/${params.id}`
                    ),
            },
            {
                path: '/retail/:id',
                element: <OurPlans />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/plans/${params.id}`
                    ),
            },
            {
                path: '/retails/:id',
                element: <SubCatagoryPlans />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/categoryPlans/${params.id}`
                    ),
            },
            {
                path: '/retails/:id',
                element: <PlansSubCatagoryPlans />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/sub-category-plans/${params.id}`
                    ),
            },
            { path: 'contact-us', element: <Contactus /> },

            {
                path: '/partnerchannels',
                element: <ADC />,
            },
            {
                path: '/partnerchannels/:id',
                element: <ADCPartnerDetails />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/adc/${params.id}`
                    ),
            },
            // {
            //   path: "/partnerchannels/bkash",
            //   element: <ADCPartnerDetailsBkash />,
            // },
            // {
            //   path: "/partnerchannels/bkash-rmg",
            //   element: <ADCPartnerDetailsBkashRMG />,
            // },
            // {
            //   path: "/partnerchannels/nagad",
            //   element: <ADCPartnerDetailsNagad />,
            // },
            // {
            //   path: "/partnerchannels/upay",
            //   element: <ADCPartnerDetailsUpay />,
            // },
            // {
            //   path: "/partnerchannels/ok-wallet",
            //   element: <ADCPartnerDetailsOkWallet />,
            // },
            // {
            //   path: "/partnerchannels/carnival",
            //   element: <ADCPartnerDetailsCarnival />,
            // },
            // {
            //   path: "/partnerchannels/jotno",
            //   element: <ADCPartnerDetailsJotno />,
            // },
            // {
            //   path: "/partnerchannels/robishop",
            //   element: <ADCPartnerDetailsRobiShop />,
            // },
            // {
            //   path: "/partnerchannels/truck-lagbe",
            //   element: <ADCPartnerDetailsTruckLagbe />,
            // },
        ],
    },
    { path: '/login', element: <Login /> },
    {
        path: '/dashboard',
        element: (
            <PrivateRoutes>
                <DashboardLayout />
            </PrivateRoutes>
        ),

        errorElement: <ErrorPage />,
        children: [
            {
                path: '/dashboard',
                element: (
                    // <AdminRoutes>
                    <Dashboard />
                    // </AdminRoutes>
                ),
            },
            {
                path: '/dashboard/admin-dashboard',
                element: <Dashboard />,
            },
            {
                path: '/dashboard/user',
                element: (
                    <AdminRoutes>
                        <Users />
                    </AdminRoutes>
                ),
            },
            {
                path: '/dashboard/user/role',
                element: (
                    <AdminRoutes>
                        <UserRole />
                    </AdminRoutes>
                ),
            },
            {
                path: '/dashboard/user/create',
                element: (
                    <AdminRoutes>
                        <UserCreate />
                    </AdminRoutes>
                ),
            },
            {
                path: '/dashboard/plans',
                element: <Plans />,
            },

            {
                path: '/dashboard/bancassurance',
                element: <DashboardBancassurance />,
            },
            {
                path: '/dashboard/bancassurance/introduction',
                element: <DashboardBancassuranceIntroduction />,
            },
            {
                path: '/dashboard/bancassurance/why-guardian-life',
                element: <DashboardBancassuranceWhyGuardinLife />,
            },
            {
                path: '/dashboard/bancassurance/services',
                element: <DashboardBancassuranceService />,
            },
            {
                path: '/dashboard/bancassurance/benefits',
                element: <DashboardBancassuranceBenfits />,
            },
            {
                path: '/dashboard/partner-logo',
                element: <PrartnerLogo />,
            },
            {
                path: '/dashboard/partner-logo-view',
                element: <PartnerLogoView />,
            },
            {
                path: '/dashboard/plans/add-plans',
                element: <DashboardAddPlans />,
            },
            {
                path: '/dashboard/plans/view-plans',
                element: <DashboardViewPlans />,
            },
            {
                path: '/dashboard/plans/category-plans',
                element: <DashboardCategoryPlans />,
            },
            {
                path: '/dashboard/plans/view-category-plans',
                element: <DashboardViewCategoryPlans />,
            },
            {
                path: '/dashboard/plans/sub-category-plans',
                element: <DashboardSubCategoryPlans />,
            },
            {
                path: '/dashboard/seo',
                element: <SEO />,
            },
            {
                path: '/dashboard/partner-channel',
                element: <DashboardPartnerChannels />,
            },

            {
                path: '/dashboard/partner-channel/partner-channel-view',
                element: <DashboardPartnerChannelsView />,
            },
            {
                path: '/dashboard/plans/view-sub-category-plans',
                element: <DashboardSubCategoryViewPlans />,
            },
            {
                path: '/dashboard/staff-list',
                element: <DashboardStaffList />,
            },
            {
                path: '/dashboard/agent-list',
                element: <DashboardAllAgentList />,
                // <DashboardAllAgentList />
                // <AgentPdfList />
            },
            {
                path: '/dashboard/booking-list',
                element: <AllBookingList />,
            },
            {
                path: '/dashboard/customer-message',
                element: <DashboardClientMessage />,
            },
            {
                path: '/dashboard/management-team',
                element: <DashbaordManagementTeam />,
            },
            {
                path: '/dashboard/extended-management-committee',
                element: <DashboardExtendManagementTeam />,
            },
            {
                path: '/dashboard/faq',
                element: <DashboardFAQ />,
            },
            {
                path: '/dashboard/faq-view',
                element: <DashboardFAQView />,
            },
            {
                path: '/dashboard/board-director',
                element: <BoardOfDirector />,
            },
            {
                path: '/dashboard/milestones',
                element: <DashboardMilestones />,
            },
            {
                path: '/dashboard/view-milestone',
                element: <DashboardMilestoneView />,
            },
            {
                path: '/dashboard/media',
                element: <DashboardMediaConer />,
            },
            {
                path: '/dashboard/digital-offer',
                element: <DashboardLoyaltyOffer />,
            },
            {
                path: '/dashboard/digital-offer/add-loyalty',
                element: <DashboardDigitalLoyaltyOffer />,
            },
            {
                path: '/dashboard/digital-offer/all-loyalty',
                element: <DashboardDigitalLoyaltyView />,
            },
            {
                path: '/dashboard/digital-offer/add-category',
                element: <DashboardLoyaltyOfferCategory />,
            },

            {
                path: '/dashboard/digital-offer/all-category',
                element: <DashboardLoyaltyOfferCategoryView />,
            },
            {
                path: '/dashboard/media/blog',
                element: <DashboardAddBlog />,
            },
            {
                path: '/dashboard/media/news',
                element: <DashboardAddNews />,
            },
            {
                path: '/dashboard/media/all-blog',
                element: <DashboardViewBlog />,
            },
            {
                path: '/dashboard/media/all-news',
                element: <DashboardViewNews />,
            },
            {
                path: '/dashboard/media/press',
                element: <DashboardPress />,
            },
            {
                path: '/dashboard/media/view-press',
                element: <DashboardPressView />,
            },
            {
                path: '/dashboard/media/video',
                element: <DashBoardVideo />,
            },
            {
                path: '/dashboard/media/view-video',
                element: <DashboardVideoView />,
            },
            {
                path: '/dashboard/media/photo',
                element: <DashBoardImageGallery />,
            },
            {
                path: '/dashboard/media/view-photo',
                element: <DashboardImageGalleryView />,
            },
            {
                path: '/dashboard/media/view-gallery/:id',
                element: <DashboardImageGallerySinglePhoto />,
            },
            {
                path: '/dashboard/media/review',
                element: <DashBoardReview />,
            },
            {
                path: '/dashboard/media/view-review',
                element: <DashboardReviewView />,
            },
            {
                path: '/dashboard/jobs',
                element: <DashboardJobs />,
            },
            {
                path: '/dashboard/jobs/post-jobs',
                element: <DashboardPostAJob />,
            },
            {
                path: '/dashboard/jobs/all-job',
                element: <DashboardViewJobPost />,
            },
            {
                path: '/dashboard/jobs/applied',
                element: <DashboardJobApplied />,
            },
            {
                path: '/dashboard/preferred-hospital',
                element: <DashboardPreferredHospital />,
            },
            {
                path: '/dashboard/preferred-hospital/all-hospital',
                element: <DashboardViewAllHospital />,
            },
            {
                path: '/dashboard/locate-branch',
                element: <DashboardLocateBranch />,
            },
            {
                path: '/dashboard/locate-branch/all-office-branch',
                element: <DashboardViewAllOfficeBranch />,
            },
            {
                path: '/dashboard/easylife',
                element: <EasylifeDashboard />,
            },
            {
                path: '/dashboard/easylife/products',
                element: <AddProduct />,
            },
            {
                path: '/dashboard/easylife/all-products',
                element: <ViewProduct />,
            },
            {
                path: '/dashboard/easylife/media',
                element: <AddMedia />,
            },
            {
                path: '/dashboard/easylife/all-media',
                element: <ViewMedia />,
            },
            {
                path: '/dashboard/claims',
                element: <DashboardClaimsForm />,
            },
            {
                path: '/dashboard/all-claims',
                element: <DashboardClaimsFormView />,
            },
            {
                path: '/dashboard/form-library',
                element: <DashboardFormLibrary />,
            },
            {
                path: '/dashboard/notice',
                element: <DashboardNoticeBoard />,
            },
            {
                path: '/dashboard/notice-view',
                element: <DashboardNoticeBoardView />,
            },
            {
                path: '/dashboard/all-form-library',
                element: <DashboardFormLibraryView />,
            },
            {
                path: '/dashboard/pop-up',
                element: <DashboardPopUp />,
            },
            {
                path: '/dashboard/all-notification',
                element: <AllNotificationView />,
            },
            {
                path: '/dashboard/leads',
                element: <DashboardLeads />,
            },
            {
                path: '/dashboard/all-leads',
                element: <DashboardLeadsView />,
            },
        ],
    },
    {
        path: '/easylife',
        element: <EasyLifeLayout />,
        errorElement: <ErrorPage />,
        children: [
            { path: '/easylife', element: <AboutEsayLife /> },
            { path: '/easylife/home', element: <AboutEsayLife /> },
            // { path: "/easylife/about-easy-life", element: <AboutEsayLife /> },
            {
                path: '/easylife/products/:id',
                element: <DetailsServiceProduct />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/easy-life-product?productCategorySlug=${params.id}`
                    ),
            },
            {
                path: '/easylife/products/Special-insurance-plan',
                element: <SpecialInsurancePlan />,
            },
            { path: '/easylife/products', element: <EasyLifeProduct /> },
            {
                path: '/easylife/easy-life-digital-offers/:name',
                element: <DigitalOfferViewAllCards />,
            },
            {
                path: '/easylife/easy-life-cancer-care-plan',
                element: <EasyLifeCancerCareCenter />,
            },
            {
                path: '/easylife/guardian-accident-care',
                element: <EasyLifeGuardianAccidentalCare />,
            },
            {
                path: '/easylife/guardian-travel-care',
                element: <EasyLifeGuardianTravelCare />,
            },
            { path: '/easylife/contact', element: <EasyLifeContact /> },
            { path: '/easylife/my-profile', element: <EasyLifeMyProfile /> },
            { path: '/easylife/faq', element: <EasyLifeFAQ /> },
            {
                path: '/easylife/privacy-policy',
                element: <EasyLifePrivacyPolicy />,
            },

            { path: '/easylife/bmi-calculator', element: <BMICalculator /> },
            {
                path: '/easylife/about-easy-life/all-media',
                element: <AboutEasylifeViewAll />,
            },
            {
                path: '/easylife/easy-life-media-single-content/:id',
                element: <MediaSingleContentDetails />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/easy-life-media/${params.id}`
                    ),
            },
            {
                path: '/easylife/easy-life-product/:id',
                element: <EasylifeSingleProductDetails />,
                loader: ({ params }) =>
                    fetch(
                        `${process.env.REACT_APP_host}/api/v1/easy-life-product/${params.id}`
                    ),
            },
        ],
    },
]);
